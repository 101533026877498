import React from 'react';
import { useHistory } from 'react-router';

const Footer = () => {
  const history = useHistory();
  const changeUrl = ( location ) => {
    history.push( location );
  }
    return (
        <div>
            <footer className="text-gray-600 body-font">
              <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                <span onClick={() => changeUrl('/')} className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                  <span className="ml-3 text-xl">GetXPic</span>
                </span>
                <p className="text-base text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2021 GetXPic.Com</p>
                <span onClick={() => changeUrl('/contact')} className="inline-flex cursor-pointer sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">Contact</span>
            </div>
          </footer>
        </div>
    );
};

export default Footer;