import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Album from '../Item/Album';

const Model = () => {
    const [ albums, setAlbums ] = useState( {} );
    const [ items, setItems ] = useState( {} );
    const { name, slug } = useParams();

    useEffect( () => {
        const url = `https://api.getxpic.com/model/${ slug }/0/25`;
        fetch( url )
        .then( res => res.json() )
        .then( data => {
            setAlbums( data );
            setItems( data.items );
        } )
    }, [] )

    const limit = parseInt( albums?.limit );
    const offset = parseInt( albums?.offset ) + limit;
    const total = parseInt( albums?.total );

    const loadMore = ( slug, offset, limit ) => {
        const url = `https://api.getxpic.com/model/${ slug }/${ offset }/${ limit }`;
        fetch( url )
        .then( res => res.json() )
        .then( data => {
            setAlbums( data );
            setItems( [...items, ...data.items] );
        })
    }
    const loadMoreButton = ( slug, offset, limit ) => {
        return <button onClick={() => loadMore( slug, offset, limit )} className="focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg">Load more...</button>
    }
    return (
        <div className="container mx-auto pt-5">
            <h1 className="font-bold text-xl p-5 text-gray-900 dark:text-gray-100">Albums of { slug }</h1>
            <section className="grid grid-cols-1 md:grid-cols-5 gap-2">
            {
                items?.map?.( album => <Album album={ album }></Album> )
            }
            </section>
            <div className="p-5 text-center">
            {
                total > offset && loadMoreButton( slug, offset, limit )
            }
            </div>
        </div>
    );
};


export default Model;