import React from 'react';

const NotFound = () => {
    return (
        <div>
            <div className="font-bold text-center">The page you are looking is not found! Please try another.</div>
        </div>
    );
};

export default NotFound;