import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Album from './components/Album/Album';
import NotFound from './components/Errors/NotFound';
import Models from './components/Models/Models';
import Model from './components/Model/Model';
import Categories from './components/Categories/Categories';
import Category from './components/Category/Category';
import Random from './components/Random/Random';
import Contact from "./components/Contact/Contact";
import Channels from "./components/Channels/Channels";

function App() {
  return (
        <>
        <Router>
          <header className="bg-white dark:bg-gray-800">
            <Header />
          </header>

          <Switch>
            <Route exact path="/">
              <main className="bg-white dark:bg-gray-800">
                <Home />
              </main>

            </Route>
            <Route path="/album/:slug">
              <Album />
            </Route>
            <Route path="/models">
              <Models />
            </Route>
            <Route path="/model/:slug">
              <Model />
            </Route>
            <Route path="/categories">
              <Categories />
            </Route>
            <Route path="/category/:slug">
              <Category />
            </Route>
            <Route path="/channels">
              <Channels />
            </Route>
            <Route path="/random">
              <Random />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>

          </Switch>
          <footer className="bg-white dark:bg-gray-800">
            <Footer />
          </footer>
        </Router>
      </>
  );
}

export default App;
