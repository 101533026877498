import React, { useEffect, useState } from 'react';
import Channel from '../Item/Channel';

const Channels = () => {
    const [channel, setChannel] = useState({});
    const [currentOrder, setCurrentOrder] = useState('az');
    const [items, setItems] = useState({});
    
    useEffect(() => {
        fetchData();
    }, []);

    const handleOrder = (order) => {
        setCurrentOrder(order);
        fetchData(order);
    }

    const fetchData = (order = 'az', offset = 0, limit = 25) => {
        fetch(`https://api.getxpic.com/channels/${order}/${offset}/${limit}`)
        .then(res => res.json())
        .then(data => {
            setChannel(data);
            setItems(data.items);
        });
    }
    const loadMore = (order, offset, limit) => {
        console.log(order, offset, limit);
        fetch(`https://api.getxpic.com/channels/${order}/${offset}/${limit}`)
        .then(res => res.json())
        .then(data => {
            setChannel(data);
            setItems([...items, ...data.items]);
        });
    }
    return (
        <div>
            <div className="container mx-auto p-5 mt-5 rounded bg-gray-200">
                <h2 className="font-bold text-xl text-gray-900 dark:text-gray-100">Order by</h2>
                <div className="flex flex-row p-5">
                    <button onClick={() => handleOrder('az')} className={currentOrder === 'az' ? 'focus:outline-none text-white text-sm py-1.5 px-5 mr-2 rounded-md bg-purple-700 hover:bg-purple-900 hover:shadow-lg' : 'focus:outline-none text-white text-sm py-1.5 px-5 mr-2 rounded-md bg-purple-400 hover:bg-purple-700 hover:shadow-lg'}>A-Z</button>
                    <button onClick={() => handleOrder('za')} className={currentOrder === 'za' ? 'focus:outline-none text-white text-sm py-1.5 px-5 mr-2 rounded-md bg-purple-700 hover:bg-purple-900 hover:shadow-lg' : 'focus:outline-none text-white text-sm py-1.5 px-5 mr-2 rounded-md bg-purple-400 hover:bg-purple-700 hover:shadow-lg'}>Z-A</button>
                    <button onClick={() => handleOrder('count')} className={currentOrder === 'count' ? 'focus:outline-none text-white text-sm py-1.5 px-5 mr-2 rounded-md bg-purple-700 hover:bg-purple-900 hover:shadow-lg' : 'focus:outline-none text-white text-sm py-1.5 px-5 mr-2 rounded-md bg-purple-400 hover:bg-purple-700 hover:shadow-lg'}>Number of Album</button>
                </div>
                <h2 className="font-bold text-xl text-gray-900 dark:text-gray-100">Search</h2>
                <div className="box container pt-6">
                    <div className="bg-white rounded flex items-center w-full p-3 shadow-sm border border-gray-200">
                        <span className="outline-none focus:outline-none"><svg className=" w-5 text-gray-600 h-5 cursor-pointer" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></span>
                        <input type="text" placeholder="Search for channel" className="w-full pl-4 text-sm outline-none focus:outline-none bg-transparent" />
                    </div>
                </div>
            </div>

            <div className="container mx-auto pt-5">
                <h2 className="font-bold text-xl p-5 text-gray-900 dark:text-gray-100">Channels</h2>
                <section className="grid grid-cols-1 md:grid-cols-5 gap-2">
                {
                    items?.map?.(model => <Channel channel={model}></Channel>)
                }
                </section>
                <div className="p-5 text-center">
                    <button onClick={() => loadMore(currentOrder, parseInt(channel?.offset) + parseInt(channel?.limit), parseInt(channel?.limit))} className="focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg">Load more...</button>
                </div>
            </div>
        </div>
    );
};

export default Channels;