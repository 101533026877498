import React from 'react';
import { Link } from 'react-router-dom';

const Album = (props) => {
    const {title, slug, thumbnail} = props.album;

    return (
        <Link to={`/album/${slug}`}>
            <img class="w-full object-cover cursor-pointer hover:opacity-75" src={thumbnail} alt={title} />
        </Link>
    );
};

export default Album;