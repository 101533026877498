import React from 'react';
import Category from './Category';

const SubCategory = (props) => {
    const {name, categories} = props;
    return (
        <div>
            <h2 className="font-bold text-xl p-5 text-gray-900 dark:text-gray-100">{name}</h2>
            <section className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-2">
                {
                    categories.map(cat => <Category cat={cat} key={cat.id}></Category>)
                }
            </section>
        </div>
    );
};

export default SubCategory;