import React from 'react';

const Category = () => {
    return (
        <div>
            category
        </div>
    );
};

export default Category;