import React from 'react';

const Album = () => {
    return (
        <div>
            Album details
        </div>
    );
};

export default Album;