import React, { useEffect, useState } from 'react';
import SubCategory from '../Item/SubCategory';

const Categories = () => {
    const [categories, setCategories] = useState({});

    useEffect(() => {
        fetch('https://api.getxpic.com/categories')
        .then(res => res.json())
        .then(data => setCategories(data))
    }, []);

    return (
        <div>
            <div className="container mx-auto p-5 mt-5 rounded bg-gray-200">
                <h2 className="font-bold text-xl text-gray-900 dark:text-gray-100">Search category</h2>
                <div className="box container pt-6">
                    <div className="bg-white rounded flex items-center w-full p-3 shadow-sm border border-gray-200">
                        <span className="outline-none focus:outline-none"><svg className=" w-5 text-gray-600 h-5 cursor-pointer" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></span>
                        <input type="text" placeholder="Search for category" className="w-full pl-4 text-sm outline-none focus:outline-none bg-transparent" />
                    </div>
                </div>
            </div>

            <div className="container mx-auto pt-5 pb-10">
                {
                    Object.entries(categories).map(category => <SubCategory name={category[0]} key={category[0]} categories={category[1]}></SubCategory>)
                }
            </div>
        </div>
    );
};

export default Categories;