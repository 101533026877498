import React from 'react';
import { Link } from 'react-router-dom';

const Category = (props) => {
    const {name, slug, count} = props.cat;
    return (
        <Link to={`/category/${slug}`}>
            <div className="bg-gray-200 hover:bg-purple-200 p-2 rounded">
                {name} <span className="bg-purple-50 p-1 rounded-xl">{count}</span>
            </div>
        </Link>
    );
};

export default Category;