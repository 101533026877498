import React, { useEffect, useState } from 'react';
import Album from '../Item/Album';

const Home = () => {
    const [albums, setAlbums] = useState({});
    const [items, setItems] = useState({});

    useEffect(() => {
        const url = 'https://api.getxpic.com/recent/0/25';
        fetch(url)
        .then(res => res.json())
        .then(data => {
            setAlbums(data);
            setItems(data.items);
        })
    }, [])

    let offset = albums?.offset;
    const limit = albums?.limit;
    const page = (offset / limit) + 1;

    const loadMore = (page, limit) => {
        const url = `https://api.getxpic.com/recent/${page}/${limit}`;
        fetch(url)
        .then(res => res.json())
        .then(data => {
            setAlbums(data);
            setItems([...items, ...data.items]);
        })
    }

    return (
        <div className="container mx-auto pt-5">
            <h1 className="font-bold text-xl p-5 text-gray-900 dark:text-gray-100">Latest albums</h1>
            <section className="grid grid-cols-1 md:grid-cols-5 gap-2">
            {
                items?.map?.(album => <Album album={album}></Album>)
            }
            </section>
            <div className="p-5 text-center">
                <button onClick={() => loadMore(page, limit)} className="focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg">Load more...</button>
            </div>
        </div>
    );
};

export default Home;