import React from 'react';
import { Link } from 'react-router-dom';

const Model = (props) => {
    const { name, slug, thumbnail, count } = props.model;

    return (
        <Link to={`/model/${slug}`}>
            <div className="relative cursor-pointer">
                <img class="w-full object-cover hover:opacity-75" src={ thumbnail } alt={ name } />
                <span className="absolute top-1 right-1 p-1 rounded-lg bg-purple-100">{ count }</span>
                <span className="absolute top-1 left-1 p-1 rounded bg-purple-50">{ name }</span>
            </div>
        </Link>
    );
};

export default Model;